<template>
    <div class="volt-container main-inner-container " style="margin-bottom: 80px;">
        <div class="main-servers-outer ">
            <div style="float: left; margin-left: 40px; width: calc(100% - 80px); margin-top: 10px;">
                <h1>
                    {{ getLocale('tg_buy_keys') }}<br/>
                    {{ getLocale('tg_buy_keys_2') }}
                </h1>
                <br/>
                <h3>
                    {{ getLocale('tg_buy_keys_3') }}<br/>
                    {{ getLocale('tg_buy_keys_4') }}<br/>
                    {{ getLocale('tg_buy_keys_5') }}<br/>
                    {{ getLocale('tg_buy_keys_6') }}<br/>
                    {{ getLocale('tg_buy_keys_7') }}<br/>
                    {{ getLocale('tg_buy_keys_8') }}<br/>
                </h3>                                    
            </div>
            <div style="float: left; margin-left: 40px; width: calc(100% - 80px); margin-top: 10px; margin-bottom: 40px;">
                <div style="width: 60px; float: left; height: 60px">
                    <img src="/img/tg.png" style="width: 60px"/>
                </div>
                <div style="float: left; height: 60px; line-height: 60px; margin-left: 20px; font-size: 30px;">
                    <a href="https://t.me/steel_wolf_bot?start=from_web">@steel_wolf_bot</a>
                </div>
            </div>
            
        </div>
    </div>
</template>

<style scoped>
.main-servers-outer {
  float: left;width: 100%; background-color: #FDD238; border-radius: 25px; margin-top: 20px;  align-items: center;  justify-content: center;
}

</style>

<script>

import Localization from '@/components/localization.js'
export default {
    name: 'MainTGComponent',
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    },
    async mounted() {   
        console.log(Localization.getLocaleString('main_title'))
    }
}

</script>