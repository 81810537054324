<template>
  <MainHeaderComponent/>
  <main>
    <MainHeaderBannerComponent/>
    <div class="main-outer-container">
      <MainTGComponent/>
      <MainOurOffersComponent/>
      <MainPLansComponent/>
      <MainServersComponent/>
    </div>
  </main>
  <MainFooterComponent/>
</template>

<style>



</style>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainHeaderBannerComponent from '@/components/MainHeaderBannerComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import MainOurOffersComponent from '@/components/MainOurOffersComponent.vue'
import MainPLansComponent from '@/components/MainPLansComponent.vue'
import MainServersComponent from '@/components/MainServersComponent.vue'
import MainTGComponent from '@/components/MainTGComponent.vue'
import Localization from '@/components/localization.js'

export default {
  name: 'HomeView',
  components: {
    MainHeaderComponent,
    MainHeaderBannerComponent,
    MainFooterComponent,
    MainOurOffersComponent,
    MainPLansComponent,
    MainServersComponent,
    MainTGComponent
  },  
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()

    // console.log('*** MAIN')
    // console.log(this._mobile)

    document.title = `${Localization.getLocaleString('main_title')}`

  },
  beforeCreate(){

  }
      

}
//var userLang = navigator.language || navigator.userLanguage;
</script>
