<template>
    <div class="volt-container main-inner-container " style="margin-bottom: 80px;">
        <div class="main_container_title" style="margin-top: 50px">
            <span>{{ getLocale('servers_1') }}</span>
        </div>

        <div class="main-servers-outer " style="margin-bottom: 80px;">
            <div class="main-server-item" v-for="(item, index) in _servers" v-bind:key="index">
                <div class="main-server-flag-outer">
                    <div class="main-server-flag-inner">
                        <img :src="getImageURL(item.country_code)"  height="50" style="margin-left: -12.5px"/>
                    </div>
                </div>
                
                <div class="main-server-info-outer">
                    <div class="main-server-info-name">
                        <span style="">{{ item.country_name }}</span>
                    </div>
                    <!-- <div class="main-server-info-info">
                        <span style="">IP: {{ item.ip_address }}</span>
                    </div> -->
                </div>

                <div class="main-server-status-outer">
                    <div class="main-server-status-inner">
                        <div class="main-server-status-box">
                            <span style="">Online</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.main-servers-outer {
  float: left;width: 100%; background-color: #EDEFFA; border-radius: 25px; margin-top: 20px;  align-items: center;  justify-content: center;
}
.main-server-item {
    width: 55%; float: left; height: 80px; margin-top: 40px; border-bottom: 1px solid #B8BDD9;;
    margin-left: 22%;
}
.main-server-flag-outer {
    width: 50px; height: 100px; float: left;
}
.main-server-flag-inner {
    width: 50px; height: 50px; border-radius: 25px; overflow: hidden; float: left
}
.main-server-info-outer {
    float: left; margin-left: 20px;
}
.main-server-info-name span  {
    color: #222040;font-size: 20px; font-style: normal; font-weight: 600;
    
}
.main-server-info-name {
    line-height: 25px; height: 25px;
    margin-top: 12px;
}
.main-server-info-info span {
    color: #222040; font-size: 16px; font-style: normal; font-weight: 400;
}
.main-server-info-info {
    line-height: 25px; height: 25px;
}
.main-server-status-box span {
    color: #FFF; font-size: 14px; font-style: normal; font-weight: 400;
}
.main-server-status-box {
    width: 100%; height: 29px; line-height: 29px; text-align: center;
}
.main-server-status-inner {
    width: 93px; height: 29px; border-radius: calc(29px / 2); background: #222040; margin-top: 11px;
}
.main-server-status-outer {
    width: 93px; height: 50px; float: right;
}

@media only screen and (max-width: 460px) {
    .main-server-item {
        width: 90%; float: left; height: 80px; margin-top: 40px; border-bottom: 1px solid #B8BDD9;;
        margin-left: 10%;
    }
    .main-server-status-outer {
        display: none;
    }

}
</style>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'
import Localization from '@/components/localization.js'

export default {
  name: 'MainServersComponent',
  components: {

  },  
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    },
    getImageURL(flag)
    {
        return '/img/flags/' + flag + '.png'
    },
    async getServers() {                
      let url = `${process.env.VUE_APP_API}/api/public/servers`;
    

      console.log(url)
      let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin" : "*"
        }
      })
      .catch(e => {
        console.log(e);

      });

      //----
      
      if (response.ok)  {            

        let json = await response.json();
        
        this._servers = json['servers']
      } 
    }
  },
  data() {
    return {
      _mobile: false,
      _servers: []
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()

    await this.getServers();

  },

}
//var userLang = navigator.language || navigator.userLanguage;
</script>