export default {
    constructor() {
        console.log(4444)
    },

    getLocaleString(id) {     
        var langs = navigator.languages

        // if('ru' in navigator.languages) 

        if(navigator.languages.includes('ru')) {
            return this.locales['ru'][id]
        } else {
            return this.locales['en-gb'][id]
        }

        // var currentLocale = navigator.language.toLowerCase()

        // if(currentLocale in this.locales)
        // {
        //     return this.locales[currentLocale][id]
        // }
        // return this.locales['en-gb'][id]        
    },
    locales: {
        'en-gb': {
            'tg_buy_keys': 'Buy VPN keys',
            'tg_buy_keys_2': 'in our Telegram bot (suitable for YouTube, WhatsApp and Instagram)',
            'tg_buy_keys_3': '🚀 Lightning speed',
            'tg_buy_keys_4': '🏄‍ Access to all sites and services',
            'tg_buy_keys_5': '💳 Payment by RU cards',
            'tg_buy_keys_6': '🌏 Large selection of server locations',
            'tg_buy_keys_7': '🔑 No logs, reliable protection on the Internet',
            'tg_buy_keys_8': '📉 The lowest price on the market - 199 rubles / month',
            'servers_1': 'Take a look at servers available',
            'home_banner_1': 'Reliable, fast',
            'home_banner_2': 'and stable',
            'home_banner_3': 'VPN service',
            'main_title': 'Volt VPN - Reliable, fast and stable VPN service'
        },
        'ru': {
            'servers_1': 'Доступные локации',
            'home_banner_1': 'Надежный,',
            'home_banner_2': 'быстрый',
            'home_banner_3': 'ВПН сервис',
            'tg_buy_keys': 'Купить ВПН ключи',
            'tg_buy_keys_2': 'в нашем Телеграм боте (подходит для ютуба, вацапа и инстаграма)',
            'tg_buy_keys_3': '🚀 Молниеносная скорость',
            'tg_buy_keys_4': '🏄‍ Доступ ко всем сайтам и сервисам',
            'tg_buy_keys_5': '💳 Оплата РУ картами',
            'tg_buy_keys_6': '🌏 Большой выбор локаций серверов',
            'tg_buy_keys_7': '🔑 Отсутствие логов, надежная защита в интернете',
            'tg_buy_keys_8': '📉 Самая низкая цена на рынке - 199 руб / месяц',
            'main_title': 'Volt VPN - Надежный, быстрый и стабильный ВПН-сервис (для ютуба, вацапа и инстаграма)'
        }
    }
}

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};